.welcome {

    .welcome-background {

        background-image: url("../../assets/images/background.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100vh;
    }

    .header {

        margin: 0 !important;
    }

    .user-name-text {

        font-size: 1.25rem;
        line-height: 160%;
        font-weight: 800;
        color: #353535;
    }

    .message-text {

        line-height: 160%;
        font-size: 1rem;
        width: 25rem;
        color: #353535;
        margin-top: 1.375rem;
    }

    .welcome-banner {

        width: 28rem;
        background-color: white;
        box-shadow: 0rem 0rem 0.5rem 0rem #cfcfcf;
        border-radius: 5px;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;

        img {

            width: 100%;
            padding: 2rem 1rem;
        }
    }

}