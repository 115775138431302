.payment-response {
  .payment-response-background {
    background-color: #4bc3c6;
    min-height: 100vh;
  }

  .header {
    margin: 0 !important;
  }

  .payment-response-name {
    font-size: 1.5rem;
    font-weight: bolder;
  }

  .response-success {
    font-size: 1rem;
    font-weight: bolder;
  }

  .card-div {
    background-image: url("../../assets/images/background.png");
    background-size: cover;
    border-radius: 20px !important;
    height: 500px;
  }
  .icon-size {
    img {
      height: 220px;
    }
  }
  .error-img {
    position: absolute;
    top: 220px;
    left: 690px;
    img {
      height: 52px;
      width: 52px;
    }
  }

  .try-button {
    font-size: small;
    background: linear-gradient(90deg, #21b8c7 0%, #87ca9e 100%);
    border-radius: 40px;
    width: 200px;
  }
  .text-1 {
    font-size: 10px;
    color: #8c8f8f;
  }

  .payment-success {
    font-size: 1.5rem;
    color: #4d4e4f;
  }

  .qr-code {
    max-width: 100%;
    object-fit: contain;
    margin-top: 1rem;
  }

  .next {
    color: #4d4e4f;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .download {
    color: #8c8f8f;
    font-weight: 500;
    font-size: 14px;
  }
}
