.user-subscribe {
  .user-subscription-background {
    background-color: #4bc3c6;
    min-height: 100vh;
  }
  .custom-card-size {
    width: 420px !important;
  }

  .subscription-name {
    font-size: 1.5rem;
    font-weight: bolder;
  }

  .button-bg {
    background-color: white;
    padding: 0.5rem;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.25);
    width: fit-content;
    margin-left: 4rem;
    font-weight: 600;
    font-size: 13px !important;
}

  .amt-div {
    width: 80%;
    font-size: 1.5rem;
    font-weight: bolder;
    margin: auto;
    background-color: white;
  }
  .amt-card {
    border-radius: 10px;
  }

  .student-list {
    text-align: left !important;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .student {
    display: flex;
  }
  .text-1 {
    font-size: 12px;
    padding: 10px;
  }

  .amt-strike {
    font-size: medium;
    color: red;
    text-decoration: line-through;
    .amount {
      color: rgb(99, 98, 98);
    }
  }

  .annual {
    color: #8c8f8f;
    font-size: 12px;
  }

  .ellipse {
    height: 15.5px;
    width: 15px;
  }

  .subscribed {
    margin: auto;

    .subscribed-tag {
      padding-top: 15px;
      img {
        width: 100px;
      }
    }
  }

  .gst-text {
    color: #979797;
    font-size: 10px;
  }

  .divider-image {
    overflow: hidden;
  }

  .disable-subscribe-button {
    background-color: rgb(224, 224, 224);
    color: black;
  }

  .disable-subscribe-button:hover {
    background-color: rgb(224, 224, 224);
    color: black;
  }
  .error-message {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
  }
}
