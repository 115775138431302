@import './style-variables.scss';

.cw-card{
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background: white;

    .cw-card-body{
        padding: 30px 30px 30px 30px;
    }

    .cw-card-footer{
        height: 3.375rem;
        background: $linear-gradiant-cw-card-footer;
        border-radius: 0px 0px 20px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .footer-text{
            color: $white;
            font-size: $font-size-18px;
            font-weight: 500;
        }
    }
}

.cw-input{
    border-radius: 1.875rem;
    width: 100%;
    height: 1.7375rem;
    background: $gray;
    border: none;
    font-size: $font-size-12px;
    padding: 1rem;
    color: $app-black !important;
}

.cw-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B2AEAB;
    opacity: 1; /* Firefox */
  }

  .cw-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #B2AEAB;
  }

  .cw-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #B2AEAB;
  }

.cw-input:focus{
    outline: none;
}

.cursor-pointer{
    cursor: pointer;
}

.cw-input-label {
    // font-weight: 600;
    font-size: $font-size-10px;
    line-height: 0.8rem;
    padding-bottom: 0.75rem;
    margin-bottom: 0 !important;
    color: $label-black;

}

.invalid {
    border: 1px solid $red !important;
}

.invalid-text {
    font-size: $font-size-12px;
    color: $red;
}

.bw-spaces-input {
    padding-top: 0.5rem;
}

.cw-background-plain {

    background-image: url("../assets/images/background.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
}

.cw-background {
    background-image: url("../assets/images/login-background.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
}

.cw-button {
    font-size: small;
    border-radius: 40px;
    width: 80%;
    background-color: #4BC3C6;
    color: white;
}

.cw-button-disable{
    background-color: $dark-gray;
    cursor: auto;
}

.cw-button:hover {
    opacity: 0.8;
    color: white;
}

.cw-button:focus {
    outline:none;
}

.cw-loader{
    border: 5px solid #f3f3f3;
    animation: spin 1s linear infinite;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
}

.right-padding {
    padding-right: 4rem;
}

.left-padding {
    padding-left: 4.4rem;
}

.cw-mt-8 {

    margin-top: 8rem;
}

.cw-mt-4 {

    margin-top: 4rem;
}

.cw-mt-2 {

    margin-top: 2rem;
}

.cw-mb-4 {

    margin-bottom: 4rem;
}

.cw-mb-2 {

    margin-bottom: 2rem;
}

.cw-mb-1 {

    margin-bottom: 1rem;
}

.star{
    color: $green
}

.logo{
    height: 8rem !important;
}

.text-white{
    color: white;
}

.parent-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 75%;
    align-items: center;
}

.child-box{
    flex:0 1 250px;
    margin: 5px;
    justify-content: center;
}

.card-header-image{
    img{
        position: absolute;
        width: 150px;
    }
}

.cw-card-size{
    width: 350px !important;
    margin: 0 !important;
}




@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }