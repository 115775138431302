.app-link {
  .app-image {
    img {
      height: 40px;
      width: 120px;
    }
  }

  .app-image-large {
    img {
      height: 60px;
      width: 180px;
    }
  }
}
