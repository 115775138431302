@import "../../../styles/style-variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.login{
    min-width: 400px;
    background-color: #f7fafc;

    .background-image{
        background-image: url('../../../assets/images/bg.jpg');
    }


    .resent-otp{
        float: right;
        font-size: 10px;
    }

    .otp-color{
        color: #4ECACE;
        cursor: pointer;
    }

    .app-link{
        width: 100px;
    }

    .otp-boxes{
        input{
            height: 40px;
            width: 45px;
            font-size: 25px;
            text-align: center;
            background: #F6F7F5;
            border: 1px solid #C4C4C4;
            box-sizing: border-box;
            border-radius: 5px;
            margin: 12px;
        }
    }

    .abs-div{
        position: absolute;
        z-index: 99999;
    }

    .disable-login{
        background-color: rgb(224, 224, 224);
        color: black;
    }

    .disable-login:hover{
        background-color: rgb(224, 224, 224);
        color: black;
    }

    .position-absolute {
        position: absolute !important;
        bottom: -7px;
        left: 0;
    }

    .abs-div{
        position: absolute;
        z-index: 99999;
    }
        
    .vh-100 {
        min-height: 100vh !important;
    }

    .bg-cover {
        background-repeat: no-repeat !important ;
        background-position: 50%;
        background-size: cover;
    }

    .text-muted {
        color: #6c757d !important;
      }

    p {
        font-size: 16px;
        color: #44415a;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        line-height: 1.7;
    }
    
    .position-relative {
        position: relative !important;
    }

    .about-area-content h2 {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .font-18 {
        font-size: 18px;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Poppins', sans-serif;
        color: #44415a;
        font-weight: 500;
        line-height: 1.4;
    }
    
    .mt-30 {
        margin-top: 30px;
    }

    .login-signup-content {
        height: 100%;
        width: 100%;
        z-index: 1;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .solid-btn-login {
        color: #fff;
        background: #4bc3c6;
        border: 2px solid#4bc3c6;
        padding: none;
    }

    .solid-btn-login:hover {
        background: #356f6f;
        color: #ffffff;
        border-color: #356f6f;
    }

    .cw-card{
        border-radius: 5px !important;
    }

    .mobile-number{    
      
        justify-content: center;
        align-items: center;
        border-radius: 0 !important;  
        width: 100%;
        height: 4rem;
        background: #ffffff;
        border: none;
        font-size: 0.75rem;
        padding: 0;
        color: #353535 !important;
        padding: 15px 0px 0 0;
        .country-code{
            border-right: 1px solid gray;
        }
       
       img{
           height: 15px;
           width: 15px;
       }

       input{
        width: 70% ;
        background: #ffffff;
       }

       input::placeholder {
       font-size: 14px;
      }
    }


    .login-signup-form .form-group label {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 88%;
        color: #696687;
    }

    .about-area-content h2 {
        font-size: 22px;
    }

    .full-screen {
        min-height: 100vh;
        position: relative;
        width: 100%;
        z-index: 1;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .box {
    background: #fff;
    border: 1px solid #dedede;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 21px 50px 0px rgba(145, 148, 160, 0.15);
    }

    .font-weight-normal {
        font-weight: 400 !important;
    }
    .color1::before {
        background: #86cba0 !important;
    }

    .color1::after {
        background: linear-gradient(to bottom, #86cba0 0%, #61c6bc 100%);
    }

    .color2::before {
        background: #61c6bc !important;
    }
    .color2::after {
        background: linear-gradient(to bottom, #61c6bc 0%, #33c1d3 100%);
    }

    .color3::before {
        background: #33c1d3 !important;
    }



    .progress-tracker {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*margin: 60px auto;*/
        margin: 60px auto 0;
    padding: 0;
    list-style: none;
    }

    .progress-step {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0%;
            flex: 1 1 0%;
    margin: 0;
    padding: 0;
    min-width: 24px;
    }

    .progress-step:last-child {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    }

    .progress-step:last-child .progress-marker::after {
    display: none;
    }

    .progress-link {
    display: block;
    position: relative;
    }

    .progress-marker {
    display: block;
    position: relative;
    }

    .progress-marker::before {
    content: attr(data-text);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    z-index: 20;
    width: 24px;
    height: 24px;
    padding-bottom: 2px;
    border-radius: 50%;
    -webkit-transition: background-color, border-color;
    transition: background-color, border-color;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    }

    .progress-marker::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -10;
    top: 10px;
    right: -12px;
    width: 100%;
    height: 4px;
    -webkit-transition: background-color 0.3s, background-position 0.3s;
    transition: background-color 0.3s, background-position 0.3s;
    }

    .progress-text {
    display: block;
    padding: 8px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    }

    .progress-title {
    margin-top: 0;
        line-height: 1.5rem;
    }

    .progress-step .progress-marker {
    color: #fff;
    }

    .progress-step .progress-marker::before {
    background-color: #b6b6b6;
    }

    .progress-step .progress-marker::after {
    background-color: #b6b6b6;
    }

    .progress-step.is-active .progress-marker::before {
    background-color: #2196F3;
    }

    .progress-step.is-complete .progress-marker::before, .progress-step.is-progress .progress-marker::before {
    background-color: #4bc3c6;
    }

    .progress-step.is-complete .progress-marker::after, .progress-step.is-progress .progress-marker::after {
    background-color: #868686;
    }

    .progress-step.is-progress-10 .progress-marker::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(10%, #868686), color-stop(10%, #b6b6b6));
    background-image: linear-gradient(to right, #868686 10%, #b6b6b6 10%);
    }

    .progress-step.is-progress-20 .progress-marker::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(20%, #868686), color-stop(20%, #b6b6b6));
    background-image: linear-gradient(to right, #868686 20%, #b6b6b6 20%);
    }

    .progress-step.is-progress-30 .progress-marker::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(30%, #868686), color-stop(30%, #b6b6b6));
    background-image: linear-gradient(to right, #868686 30%, #b6b6b6 30%);
    }

    .progress-step.is-progress-40 .progress-marker::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(40%, #868686), color-stop(40%, #b6b6b6));
    background-image: linear-gradient(to right, #868686 40%, #b6b6b6 40%);
    }

    .progress-step.is-progress-50 .progress-marker::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #868686), color-stop(50%, #b6b6b6));
    background-image: linear-gradient(to right, #868686 50%, #b6b6b6 50%);
    }

    .progress-step.is-progress-60 .progress-marker::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(60%, #868686), color-stop(60%, #b6b6b6));
    background-image: linear-gradient(to right, #868686 60%, #b6b6b6 60%);
    }

    .progress-step.is-progress-70 .progress-marker::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(70%, #868686), color-stop(70%, #b6b6b6));
    background-image: linear-gradient(to right, #868686 70%, #b6b6b6 70%);
    }

    .progress-step.is-progress-80 .progress-marker::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(80%, #868686), color-stop(80%, #b6b6b6));
    background-image: linear-gradient(to right, #868686 80%, #b6b6b6 80%);
    }

    .progress-step.is-progress-90 .progress-marker::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(90%, #868686), color-stop(90%, #b6b6b6));
    background-image: linear-gradient(to right, #868686 90%, #b6b6b6 90%);
    }

    .progress-step:hover .progress-marker::before {
    background-color: #56ADF5;
    }

    .progress-tracker--text .progress-step:last-child, .progress-tracker--center .progress-step:last-child, .progress-tracker--right .progress-step:last-child {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    }

    .progress-tracker--center {
    text-align: center;
    }

    .progress-tracker--center .progress-marker::before, .progress-tracker--center .progress-text--dotted::before {
    margin-left: auto;
    margin-right: auto;
    }

    .progress-tracker--center .progress-marker::after {
    right: -50%;
    }

    .progress-tracker--right {
    text-align: right;
    }

    .progress-tracker--right .progress-marker::before, .progress-tracker--right .progress-text--dotted::before {
    margin-left: auto;
    }

    .progress-tracker--right .progress-marker::after {
    right: calc(-100% + 12px);
    }

    .progress-tracker--spaced .progress-marker::after {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    }

    .progress-tracker--border {
    padding: 4px;
    border: 2px solid #333;
    border-radius: 32px;
    }

    .progress-tracker--theme-red .progress-step .progress-marker {
    color: #fff;
    }

    .progress-tracker--theme-red .progress-step .progress-marker::before {
    background-color: #666;
    }

    .progress-tracker--theme-red .progress-step .progress-marker::after {
    background-color: #666;
    }

    .progress-tracker--theme-red .progress-step .progress-text {
    color: #333;
    }

    .progress-tracker--theme-red .progress-step.is-active .progress-marker::before {
    background-color: #A62D24;
    }

    .progress-tracker--theme-red .progress-step.is-complete .progress-marker::before {
    background-color: #D93B30;
    }

    .progress-tracker--theme-red .progress-step.is-complete .progress-marker::after {
    background-color: #333;
    }

    .progress-tracker--theme-red .progress-step:hover .progress-marker::before {
    background-color: #DF7B74;
    }

    .progress-text--dotted::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    margin: 6px -2px;
    background-size: 12px 18px;
    background-image: repeating-radial-gradient(circle at center 6px, #b6b6b6, #b6b6b6 5px, rgba(182, 182, 182, 0.5) 5.5px, rgba(182, 182, 182, 0.01) 6px, transparent 100%);
    }

    .progress-text--dotted-1::before {
    height: 12px;
    }

    .progress-text--dotted-2::before {
    height: 30px;
    }

    .progress-text--dotted-3::before {
    height: 48px;
    }

    .progress-text--dotted-4::before {
    height: 66px;
    }

    .progress-text--dotted-5::before {
    height: 84px;
    }

    .progress-text--dotted-6::before {
    height: 102px;
    }

    .progress-text--dotted-7::before {
    height: 120px;
    }

    .progress-text--dotted-8::before {
    height: 138px;
    }

    .progress-text--dotted-9::before {
    height: 156px;
    }

    .progress-text--dotted-10::before {
    height: 174px;
    }

    .progress-text--dotted-11::before {
    height: 192px;
    }

    .progress-text--dotted-12::before {
    height: 210px;
    }

    .progress-tracker--text-top .progress-text {
    height: 100%;
    }

    .progress-tracker--text-top .progress-marker {
    top: -24px;
    }

    .progress-tracker--text-inline {
    overflow: hidden;
    }

    .progress-tracker--text-inline .progress-step, .progress-tracker--text-inline .progress-marker {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    }

    .progress-tracker--text-inline .progress-marker {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    }

    .progress-tracker--text-inline .progress-marker::after {
    top: auto;
    }

    .progress-tracker--text-inline .progress-text {
    position: relative;
    z-index: 30;
    max-width: 70%;
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
    }

    .progress-tracker--text-inline .progress-marker .progress-text {
    display: inline-block;
    }

    .progress-tracker--text-inline .progress-title {
    margin: 0;
    }

    .progress-tracker--square .progress-marker::before {
    border-radius: 0;
    }

    .progress-tracker--square .progress-marker::after {
    top: auto;
    bottom: 0;
    }

    @media (max-width: 575px) {
    .progress-tracker-wrapper {
        overflow-x: auto;
        -ms-scroll-snap-type: x proximity;
            scroll-snap-type: x proximity;
    }
    .progress-tracker-wrapper .progress-step {
        min-width: 50%;
        scroll-snap-align: start;
    }
    }

    .progress-tracker--vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    }

    .progress-tracker--vertical .progress-step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    }

    .progress-tracker--vertical.progress-tracker--right .progress-step {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    }

    .progress-tracker--vertical .progress-marker::after {
    right: auto;
    top: 12px;
    left: 10px;
    width: 4px;
    height: 100%;
        z-index: 9;
    }

    .progress-tracker--vertical .progress-text {
    padding: 0 20px 50px 20px;
    }

    @-webkit-keyframes scale-up {
    from {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(0);
                transform: translate(-50%, -50%) scale(0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
    }
    }

    @keyframes scale-up {
    from {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(0);
                transform: translate(-50%, -50%) scale(0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
    }
    }

    .anim-ripple .progress-link::before, .anim-ripple-large .progress-link::before, .anim-ripple-splash .progress-link::before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 30;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    visibility: hidden;
    }

    .anim-ripple .progress-link:not(:active)::before, .anim-ripple-large .progress-link:not(:active)::before, .anim-ripple-splash .progress-link:not(:active)::before {
    -webkit-animation: scale-up 0.3s ease-out;
            animation: scale-up 0.3s ease-out;
    }

    .anim-ripple .progress-link:focus::before, .anim-ripple-large .progress-link:focus::before, .anim-ripple-splash .progress-link:focus::before {
    visibility: visible;
    }

    .anim-ripple.progress-tracker--center .progress-link::before, .anim-ripple.progress-tracker--center .progress-link::after, .progress-tracker--center .anim-ripple .progress-link::before, .progress-tracker--center .anim-ripple .progress-link::after, .anim-ripple-large.progress-tracker--center .progress-link::before, .anim-ripple-large.progress-tracker--center .progress-link::after, .progress-tracker--center .anim-ripple-large .progress-link::before, .progress-tracker--center .anim-ripple-large .progress-link::after, .anim-ripple-splash.progress-tracker--center .progress-link::before, .anim-ripple-splash.progress-tracker--center .progress-link::after, .progress-tracker--center .anim-ripple-splash .progress-link::before, .progress-tracker--center .anim-ripple-splash .progress-link::after, .anim-ripple-double.progress-tracker--center .progress-link::before, .anim-ripple-double.progress-tracker--center .progress-link::after, .progress-tracker--center .anim-ripple-double .progress-link::before, .progress-tracker--center .anim-ripple-double .progress-link::after {
    left: 50%;
    }

    .anim-ripple.progress-tracker--right .progress-link::before, .anim-ripple.progress-tracker--right .progress-link::after, .progress-tracker--right .anim-ripple .progress-link::before, .progress-tracker--right .anim-ripple .progress-link::after, .anim-ripple-large.progress-tracker--right .progress-link::before, .anim-ripple-large.progress-tracker--right .progress-link::after, .progress-tracker--right .anim-ripple-large .progress-link::before, .progress-tracker--right .anim-ripple-large .progress-link::after, .anim-ripple-splash.progress-tracker--right .progress-link::before, .anim-ripple-splash.progress-tracker--right .progress-link::after, .progress-tracker--right .anim-ripple-splash .progress-link::before, .progress-tracker--right .anim-ripple-splash .progress-link::after, .anim-ripple-double.progress-tracker--right .progress-link::before, .anim-ripple-double.progress-tracker--right .progress-link::after, .progress-tracker--right .anim-ripple-double .progress-link::before, .progress-tracker--right .anim-ripple-double .progress-link::after {
    left: calc(100% - 12px);
    }

    .anim-ripple-splash .progress-link::before {
    width: 48px;
    height: 48px;
    -webkit-box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.35);
            box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.35);
    }

    .anim-ripple-double .progress-link::before, .anim-ripple-double .progress-link::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 30;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    visibility: hidden;
    background: none;
    border: 3px solid rgba(0, 0, 0, 0.3);
    }

    .anim-ripple-double .progress-link:not(:active)::before, .anim-ripple-double .progress-link:not(:active)::after {
    -webkit-animation: scale-up 0.3s ease-out 0s;
            animation: scale-up 0.3s ease-out 0s;
    }

    .anim-ripple-double .progress-link:not(:active)::after {
    -webkit-animation-delay: 0.15s;
            animation-delay: 0.15s;
    }

    .anim-ripple-double .progress-link:focus::before, .anim-ripple-double .progress-link:focus::after {
    visibility: visible;
    }

    .anim--large .progress-link::before, .anim--large .progress-link::after {
    width: 48px;
    height: 48px;
    }

    .anim--path .progress-marker::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #b6b6b6), color-stop(50%, #868686));
    background-image: linear-gradient(to right, #b6b6b6 50%, #868686 50%);
    background-size: 200% 100%;
    background-position: 0% 100%;
    -webkit-transition: background-position 0.3s ease-out;
    transition: background-position 0.3s ease-out;
    }

    .progress-step.is-complete .anim--path .progress-marker::after {
    background-position: -100% 100%;
    }

    .anim--path .progress-step.is-complete .progress-marker::after {
    background-position: -100% 100%;
    }

    [dir="rtl"] .progress-marker::after {
    right: auto;
    left: -12px;
    }

    [dir="rtl"] .progress-tracker--center .progress-marker::after {
    left: -50%;
    }

    

}



.country-code-drop-down{
    max-height: 190px;
    min-height: 120px;
    width: 290px;
    overflow-y: scroll;
    background: white;
    border-radius: 5px;
    border: 1px solid #d7d8d6;
    .search{
        position: sticky;
        top: 0;
        z-index: 999999;
        input{
            background: #F6F7F5;
            border: 1px solid #F6F7F5;
            border-radius: 50px;
            font-size: 12px;
            width: -webkit-fill-available;
        }

        input:focus{
            background: #F6F7F5;
            border: 1px solid #F6F7F5;
        }

        label{
            margin: 0 !important;
            font-size: 14px;
        }

    }

    .dropdown-list{
        .country-name{
            font-size: 12px;
        }
        .country-name:hover{
            font-weight: 700;
        }
    }


    .no-data{
        label{
            font-size: 14px;
        }
    }

}


#st-3::-webkit-scrollbar {
    width: 6px;
    padding-right: 5px;
  }

#st-3::-webkit-scrollbar-thumb {
    background-color: #999999;
  }
