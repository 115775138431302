.App {
  // text-align: center;
  min-width: 72rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header {

  height: 4rem;

  .logo {

      padding: 0.8rem 0 0 4rem;
      height: 4rem;
  }

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: Mont;
  src: url("../src/home/assets/fonts/Mont/Mont-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("../src/home/assets/fonts/Mont/Mont-RegularItalic.woff")
      format("woff");
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: Mont;
  src: url("../src/home/assets/fonts/Mont/Mont-Bold.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("../src/home/assets/fonts/Mont/Mont-BoldItalic.woff")
      format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Mont;
  src: url("../src/home/assets/fonts/Mont/Mont-SemiBold.woff")
      format("woff");
  font-weight:600;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("../src/home/assets/fonts/Mont/Mont-SemiBoldItalic.woff")       
       format("woff");
  font-weight:600;
  font-style: italic;
}

@font-face {
  font-family: Mont;
  src:url("../src/home/assets/fonts/Mont/Mont-Light.woff")
      format("woff");
  font-weight: 300; 
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("../src/home/assets/fonts/Mont/Mont-LightItalic.woff")
      format("woff");
  font-weight:300;
  font-style: italic;
}

@font-face {
  font-family: Mont;
  src: url("../src/home/assets/fonts/Mont/Mont-Thin.woff")
       format("woff");
  font-weight:100;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src:  url("../src/home/assets/fonts/Mont/Mont-ThinItalic.woff")
       format("woff");
  font-weight:100;
  font-style: italic;
}