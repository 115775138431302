.cw-header {

    height: 4rem;
    position: fixed;
    width: 100%;
    z-index: 1000;
    top:0;
    padding: 0 2rem;

    .logo {

        padding: 0.8rem 0 0 0rem;
        height: 3.2rem;
    }

    .text {

        margin: 0.2rem;
        font-size: 0.7rem;
        letter-spacing: 0.01;
        color:black;
        cursor: pointer;
        padding-right: 0.5rem;
    }

    .text-icon {

        height: 2rem;
        cursor: pointer;
    }

    .bg-white {
        background-color: white;
    }
}
