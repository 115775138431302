.school-coupon {
    .user-subscription-background {

        background-color: #4BC3C6;
        min-height: 100vh
    }

    .cw-card{
        position: relative;
    }

    .subscription-name{
        font-size: 1.5rem;
        font-weight: bolder;
    }
    .icon-size{
        img{
            height: 220px;
        }
    }
    .position-relative{
        position: relative;
    }

    .amt-div{
        width: 80%;
        font-size: 1.5rem;
        font-weight: bolder;
        margin: auto;
        background-color: white;
    }
    .amt-card{
        border-radius: 10px;
    }

    .student-list{
        text-align: left !important;
    }

    .flex{
        display: flex;
        align-items: center;
    }

    .student{
        display: flex;
    }
    .text-1{
        font-size: 12px;
        padding: 10px;
    }

    .amt-strike{
        font-size: medium;
        color:red;
        text-decoration:line-through;
        .amount{
            color:rgb(99, 98, 98);

        }
    }

    .annual{
        color: #8C8F8F;
        font-size: 12px;
    }

    .ellipse{
        height: 15.5px;
        width: 15px
    }

    .subscribed{
            margin: auto;

        .subscribed-tag{
            padding-top: 15px;
            img{
                width: 100px;
            }
        }
    }

    .gst-text{
        color:#979797;
        font-size: 10px;
    }

    .divider-image{
        overflow: hidden;
    }

    .disable-subscribe-button{
        background-color: rgb(224, 224, 224);
        color: black;
    }

    .disable-subscribe-button:hover{
        background-color: rgb(224, 224, 224);
        color: black;
    }
    .error-message{
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
    }

    .giftbox{
        width: 30px;
    }

    .offer-title{
        font-weight: bold;
        font-size: 12px;
        color: #444444;
    }

    .offer-content{
        font-size: 12px;
        color: #444444;
    }

    .offer-card{

        .background{
            position: absolute;
            z-index: 999;
            img{
                width: 335px;
            }
        }
    }
    .expired-card{

        .expired-image{
            img{
                width: 300px;
            }
        }

        .opps{
            font-weight: bold;
            font-size: 24px;
            line-height: 160%;
            text-align: center;
            color: #000000;
        }
        .text-1{
            font-size: 14px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.02em;
            color: #535353;
        }

        .footer{
            padding-top: 100px;
            padding-bottom:20px;

            .upgrade{
                font-weight: bold;
                font-size: 14px;
                letter-spacing: 0.02em;
                color: #535353;  
            }
        }


    }

    .hurry{
        font-size: 10px;
        color: #FF8B8B;
        font-weight: 700;
    }

    .shadow-card{
        height: 100%;
        background: #00000085;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-radius: 10px;
    }

    .plan-wrapper{
        position: relative;
    }
}

.subscription-card{
    border-radius: 10px;
    padding: 20px;
    position: absolute;
    bottom:0;
    background-color: white;
    box-shadow: 1px -20px 16px rgba(0, 0, 0, 0.15);

    .plan-name{
        font-weight: bold;
        font-size: 16px;
        line-height: 25px;
        color: #353535;
    }

    .plan-description{
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #7D7D7D;
    }

    .amount{

        font-weight: bold;
        font-size: 20px;
        line-height: 130%;

        color: #353535;
    }

    .close-icon{
        width: 15px;
    }

    .plan-card{
        border-radius: 15px;
        background-color: #F2EEFF;
        margin: 0;
    }

    .plan-card-2{
        background-color: #E9F7FF;
    }

}