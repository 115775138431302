.singup {
  .signup-background {
    background-color: #4bc3c6;
    min-height: 100vh;
  }

  .parent-box {
    // height: 85vh !important;
  }
  .abs-div {
    position: absolute;
    z-index: 99999;
  }

  .number {
    border-radius: 20px !important;
  }

  .type-wrapper {
    font-size: 14px;
    color: #263238;
  }

  .curve-top {
    position: absolute;
    width: 100%;
  }

  .curve-bottom {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .radio-button {
    width: 20px;
  }

  .cw-card {
    position: relative;
  }

  .cw-card-size {
    width: 400px !important;
    margin: 0 !important;
    min-height: 500px;
  }

  .mobile-number {
    padding: 5px 0;
  }

  .cw-input-lable {
    font-weight: 600;
    font-size: 14px;
    color: #263238;
  }

  .fixed-height {
    height: 45px !important;
  }
  .resent-otp {
    float: right;
    font-size: 10px;
  }

  .otp-color {
    color: #4ecace;
    cursor: pointer;
  }

  .otp-boxes {
    input {
      height: 40px;
      width: 45px;
      font-size: 25px;
      text-align: center;
      background: #f6f7f5;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 5px;
      margin: 12px;
    }
  }

  .text-2 {
    color: #979797;
    font-size: 12px;
    font-weight: 400;
  }

  .tc {
    font-size: 12px;
    font-weight: 400;
    color: #05c5d9;
  }

  .otp-sent {
    font-size: 14px;
  }

  .mobile-number-wrapper {
    justify-content: center;
    align-items: center;
    // border-radius: 0 !important;
    width: 100%;
    background: #f6f7f5;
    border: none;
    font-size: 0.75rem;
    padding: 0;
    color: #353535 !important;
    padding: 0px 0px 0 0;
    border-radius: 20px !important;

    .country-code {
      border-right: 1px solid gray;
    }

    img {
      height: 15px;
      width: 15px;
    }

    input {
      width: 70%;
      background: #f6f7f5;
    }

    input::placeholder {
      font-size: 14px;
    }
  }
  .position-relative {
    position: relative;
  }

  .gender-image {
    width: 100px;
  }

  .amt-div {
    width: 80%;
    font-size: 1.5rem;
    font-weight: bolder;
    margin: auto;
    background-color: white;
  }
  .amt-card {
    border-radius: 10px;
  }

  .student-list {
    text-align: left !important;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .student {
    display: flex;
  }
  .text-1 {
    font-size: 12px;
    padding: 10px;
  }

  .amt-strike {
    font-size: medium;
    color: red;
    text-decoration: line-through;
    .amount {
      color: rgb(99, 98, 98);
    }
  }

  .btn-primary {
    background: linear-gradient(90deg, #05c5d9 0%, #5fd590 100%);
    border-radius: 40px;
    border: none;
    cursor: pointer;
  }

  .gst-text {
    color: #979797;
    font-size: 10px;
  }

  .divider-image {
    overflow: hidden;
  }

  .disable-subscribe-button {
    background-color: rgb(224, 224, 224);
    color: black;
  }

  .disable-subscribe-button:hover {
    background-color: rgb(224, 224, 224);
    color: black;
  }
  .error-message {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
  }

  .giftbox {
    width: 30px;
  }

  .offer-title {
    font-weight: bold;
    font-size: 12px;
    color: #444444;
  }

  .offer-content {
    font-size: 12px;
    color: #444444;
  }

  .offer-card {
    .background {
      position: absolute;
      z-index: 999;
      img {
        width: 335px;
      }
    }
  }
  .expired-card {
    .expired-image {
      img {
        width: 300px;
      }
    }

    .opps {
      font-weight: bold;
      font-size: 24px;
      line-height: 160%;
      text-align: center;
      color: #000000;
    }
    .text-1 {
      font-size: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.02em;
      color: #535353;
    }

    .footer {
      padding-top: 100px;
      padding-bottom: 20px;

      .upgrade {
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #535353;
      }
    }
  }

  .hurry {
    font-size: 10px;
    color: #ff8b8b;
    font-weight: 700;
  }

  .shadow-card {
    height: 100%;
    background: #00000085;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 10px;
  }

  .plan-wrapper {
    position: relative;
  }
}
