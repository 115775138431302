
/** FONTS */
$font-size-10px: 0.6rem;
$font-size-11px: 0.6875rem;
$font-size-12px: 0.75rem;
$font-size-13px: 0.78rem;
$font-size-14px: 0.84rem;
$font-size-15px: 0.9375rem;
$font-size-16px: 1rem;
$font-size-18px: 1.125rem;
$font-size-20px: 1.25rem;
$font-size-22px: 1.375rem;
$font-size-32px: 2rem;


/** COLORS */
$white : #FFFFFF;
$app-black:#353535;
$label-black :#263238;
$green :#87CA9E;
$gray : #F6F7F5;
$red :#F56D77;
$report-gb-color: rgb(245,246,250);
$report-filter-label: #7D7D7D;

$gray-report-bg: #E5E5E5;
$green-box: rgba(106, 229, 211, 0.2);
$yellow-box: rgba(255, 184, 0, 0.2);
$red-box: rgba(243, 112, 121, 0.2);
$header-color: #21B8C7;
$pink :#FEE9ED;
$dark-red : #D21A3C;
$light-red :#fff2f1;
$gray-white: #F5F6FA;
$dark-gray : #d1d3d5;
$green-forgot-password:#86cb9f;



/** SIGN UP LOGIN PAGE */
$linear-gradiant-cw-card-footer : linear-gradient(90deg, #21B8C7 0%, #87CA9E 100%)