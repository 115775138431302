@import "../../../styles/style-variables.scss";

.course-subscription-amount {
  background-color: #f2eeff;
  border-radius: 10px;

  .header-name {
    font-size: 16px;
    font-weight: 700;
  }

  .course-container {
    font-size: 12px;

    .amt-strike {
      font-weight: bolder;
      font-size: 14px;
      .amount {
        color: rgb(99, 98, 98);
      }
    }

    .payable-amount {
      font-weight: 800;
      font-size: 14px;
      color: #6b52c8;
    }
  }

  .ellipse {
    height: 12px;
    width: 12px;
    vertical-align: top;
    margin-top: 3px;
  }

  .coming-soon {
    position: absolute;
    top: -18px;
    right: 0;
    font-size: 10px;
    color: white;
    background-color: #4bc3c6;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 1px 5px;
  }

  .course-header-name {
    font-size: 12px;
    font-weight: 700;
  }

  .valid-till {
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    color: #7d7d7d;
  }

  .amt-strike {
    font-weight: bolder;
    font-size: 20px;
    line-height: 130%;
    .amount {
      color: rgb(99, 98, 98);
    }
  }

  .total-amount {
    font-size: 22px;
    line-height: 130%;
    font-weight: bolder;
  }

  .discount {
    font-weight: bold;
    font-size: 12px;
    color: #535353;
    .coupon {
      overflow: hidden;
      border-radius: 50px;
      input {
        border-radius: 50px 0px 0px 50px;
      }
      .apply {
        padding-top: 7px;
        height: 32px;
        color: white;
        background: #8a78cd;
        border-radius: 0px 50px 50px 0px;
      }
      .apply-invalid {
        height: 34px !important;
      }
    }
    .uppercase {
      text-transform: uppercase;
    }
    ::placeholder {
      text-transform: none;
    }
  }

  .applied-coupon {
    display: inline-block;
    font-size: 10px;
    border: 0.5px solid #979797;
    border-radius: 50px;
    width: fit-content;
    padding: 3px 10px;

    .flex {
      display: flex;
      align-self: center;
    }

    .discount-text {
      color: #535353;
      font-size: 11px;
    }

    .coupon-amt {
      font-size: 14px;
      font-weight: 700;
    }

    .remove-discount {
      height: 12px;
      width: 12px;
    }
  }

  .divider-img {
    width: 90%;
    overflow: hidden;
  }

  .payable-amount {
    font-weight: 800;
    font-size: 22px;
    line-height: 130%;
    color: #6b52c8;
  }

  .error-msg {
    color: #fc8e8e;
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
  }

  .view-plan {
    position: absolute;
    padding-top: 15px;
    padding-left: 80px;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #0a84ff;
  }

  .my-country-price {
    font-size: 9px;
    width: 100% !important;
    color: #ae0404;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.font-14 {
  font-size: $font-size-14px;
}
