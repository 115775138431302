.user-list {
    .user-subscription-background {

        background-color: #4BC3C6;
        min-height: 100vh
    }

    .subscription-name{
        font-size: 1.5rem;
        font-weight: bolder;
    }

    .note{
        display: flex;
        background-color: #E6F4EC;
        border-radius: 10px;
    }

    .bulb-img{
        width: 20px;
    }

    .student-list{
        text-align: left !important;
    }
    .cw-input{
        min-height: 40px;
    }

    .flex{
        display: flex;
        align-items: center;
    }

    .student{
        display: flex;
    }
    .text-1{
        font-size: 12px;
        padding: 10px;
    }

    .amt-strike{
        font-size: medium;
        color:red;
        text-decoration:line-through;
        .amount{
            color:rgb(99, 98, 98);

        }
    }

    .annual{
        color: #8C8F8F;
        font-size: 12px;
    }

    .ellipse{
        height: 15.5px;
        width: 15px
    }

    .subscribed{
            margin: auto;

        .subscribed-tag{
            padding-top: 15px;
            img{
                width: 100px;
            }
        }
    }

    .gst-text{
        color:#979797;
        font-size: 10px;
    }

    .divider-image{
        overflow: hidden;
    }

    .disable-subscribe-button{
        background-color: rgb(224, 224, 224);
        color:gray;
    }

    .disable-subscribe-button:hover{
        background-color: rgb(224, 224, 224);
        color: gray;
    }
    .error-message{
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
    }
}